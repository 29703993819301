<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>Section</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Section Title *"
                    v-model="section.sectionTitle"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Title'"
                    @blur="$v.section.sectionTitle.$touch()"
                    :is-valid="
                      $v.section.sectionTitle.$dirty
                        ? !$v.section.sectionTitle.$error
                        : null
                    "
                  >
                  </CInput>
                  <CInput
                    label="Priority "
                    v-model="section.priority"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please Enter the Priority'"
                  />
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" />Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="saveSpeciality()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" />Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "Section",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },
      sectionId: "",
      section: {
        sectionId: "",
        sectionTitle: "",
        priority: "0",
        permitFormId: this.$route.params.permitFormId,
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },

  validations: {
    section: {
      sectionTitle: {
        required,
      },
      //   priority:{
      //        required,

      //   },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
      this.permitFormId = this.$route.params.permitFormId;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.sectionId = this.$route.params.sectionId;
      this.permitFormId = this.$route.params.permitFormId;
      this.getUser();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.sectionId = this.$route.params.sectionId;
      this.permitFormId = this.$route.params.permitFormId;
      this.getUser();
    }
  },
  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({
        name: "Sections",
        params: { permitFormId: this.$route.params.permitFormId },
      });
    },
    saveSpeciality() {
      this.$v.section.$touch();
      if (this.$v.section.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.section));
      } else {
        this.apiPostSecure(
          process.env.VUE_APP_API_HOST +
            "/emapp/web/secure/save/workPermit/sections",
          this.section
        ).then((post) => {
          this.$router.push({
            name: "Sections",
            params: { permitFormId: this.$route.params.permitFormId },
          });
        });
      }
    },
    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getUser() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/workPermit/section/" +
          this.sectionId
      )
        .then((response) => response.json())
        .then((data) => (this.section = data));
    },
  },
  mounted() {},
};
</script>
